<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card class="pb-5">
      <v-card-title class="justify-center">{{ textCard.title }}</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="handleSubmit"
        >
          <v-row>
            <v-col cols="12" md="12" class="text-center">
              <v-hover v-slot:default="{ hover }">
                <v-list-item-avatar
                  class="mx-auto"
                  color="grey"
                  size="100"
                >
                  <croppa
                    v-model="croppa"
                    ref="croppa"
                    :width="100"
                    :height="100"
                  >
                    <img
                      v-if="partner.id"
                      slot="placeholder"
                      :src="partner.photo 
                        ? `${apiUrl}/images/partner/${partner.photo}` : `/avatar.png`"
                    >
                    <img
                      v-else
                      slot="placeholder"
                      src="/avatar.png"
                    >

                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-ease-in-ease-out orange darken-2 v-card--reveal display-3 white--text"
                        style="height: 100%;"
                      >
                        <v-btn
                          text
                          outlined
                          color="white"
                          class
                          style="opacity: 1;"
                          fab
                          large
                          top
                          @click="$refs.croppa.chooseFile()"
                        >
                          <div class="text-center">
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  fab
                                  class="mx-2"
                                  color="grey"
                                  v-on="on"
                                >
                                  <v-icon>mdi-camera-plus</v-icon>
                                </v-btn>
                              </template>
                              <span>Carregar foto</span>
                            </v-tooltip>
                          </div>
                        </v-btn>
                      </div>
                    </v-expand-transition>
                    
                  </croppa>
                </v-list-item-avatar>
              </v-hover>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="partner.name"
                :readonly="show"
                label="Nome completo"
                dense
                prepend-inner-icon="mdi-account"
                outlined
                hide-details
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="partner.email"
                :readonly="show"
                label="Email"
                dense
                prepend-inner-icon="mdi-email"
                outlined
                hide-details
                required
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="partner.telephone"
                :readonly="show"
                label="Telefone"
                dense
                prepend-inner-icon="mdi-phone"
                outlined
                hide-details
                required
                type="number"
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col v-if="!show" cols="12" md="6">
              <v-text-field
                v-model.trim="partner.password"
                label="Senha"
                type="password"
                dense
                prepend-inner-icon="mdi-lock"
                outlined
                hide-details
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col v-if="!show" cols="12" md="6">
              <v-text-field
                v-model.trim="repeatPassword"
                label="Confirmar Senha"
                type="password"
                dense
                prepend-inner-icon="mdi-lock"
                outlined
                hide-details
                :rules="repeatPasswordRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="partner.office"
                :readonly="show"
                label="Histórico Profissional"
                auto-grow
                outlined
                rows="1"
                row-height="15"
                hide-details
                required
                :rules="requiredRules"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                v-model="partner.role"
                :readonly="show"
                label="Nivel de Acesso"
                :items="roles"
                item-text="name"
                item-value="id"
                prepend-inner-icon="mdi-folder-key"
                dense
                outlined
                hide-details
                required
                :rules="requiredRules"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
            <v-btn
              color="primary"
              :outlined="!show"
              class="mx-2"
              @click="$emit('close')"
            >{{ show ? 'Fechar' : 'Cancelar'}}</v-btn>
            <v-btn
              v-if="!show"
              :disabled="isLoading"
              type="submit"
              color="primary"
            >Salvar</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <error-dialog :dialog="showError" :message="error" @close="closeErrorDialog" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </v-dialog>
</template>

<script>
import { API_URL } from '@/api'
import { CREATE_PARTNER_MUTATION, UPDATE_PARTNER_MUTATION } from './../graphql/Mutation'
import { GET_ROLES_PARTNER_QUERY } from './../graphql/Query'
import { mapActions } from 'vuex'
import { formatError } from '@/utils'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from '../../../components/SuccessDialog.vue'
import { ADD_DIALOG } from '@/mixins/dialog'
export default {
  name: 'AddPartnerDialog',
  components: { ErrorDialog, ProgressDialog, SuccessDialog },
  mixins: [
    ADD_DIALOG
  ],
  props: {
    dialog: Boolean,
    partner: Object,
    show: Boolean
  },
  data: () => ({
    apiUrl: API_URL,
    croppa: {},
    repeatPassword: '',
    roles: []
  }),
  apollo: {
    roles: {
      query: GET_ROLES_PARTNER_QUERY
    }
  },
  watch: {
    partner () {
    this.partner.office = this.partner.user.office
  }
  },
  computed: {
    textCard () {
      return this.partner.id
        ? { title: "Editar parceiro"}
        : { title: "Cadastrar parceiro"}
    },
    repeatPasswordRules () {
      return [v => v === this.partner.password || "Senha dferente"]
    },
    emailRules (){
      return [
        v => !!v || "Campo obrigatório",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ]
    },
    requiredRules () {
      return [v => !!v || "Campo obrigatório"]
    } 
  },
  methods: {
    ...mapActions({
      setPartnerState: 'partner/setPartner',
      updatePartnerState: 'partner/updatePartner'
    }),
    closeErrorDialog (value) {
      this.showError = value;
    },
    async handleSubmit () {
      this.isLoading = true
      if (this.croppa.hasImage()) {
        this.partner.newPhoto = this.croppa.generateDataUrl('image/jpeg', 0.8)
      }
      try {
        if (this.partner.id) {
          const { data } = await this.$apollo.mutate({
            mutation: UPDATE_PARTNER_MUTATION,
            variables: { userInput: this.partner }
          })
          this.updatePartnerState(data.updatePartner)
          this.success = 'Parceiro actualizado com sucesso.'
        } else {
          const { data } = await this.$apollo.mutate({
            mutation: CREATE_PARTNER_MUTATION,
            variables: { userInput: this.partner }
          })
          this.setPartnerState(data.createPartner)
          this.success = 'Parceiro adicionado com sucesso.'
        }
        this.$emit('close')
        this.showSuccess = true
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.show-btns:not(.on-hover) {
  color: #f7a400 !important;
}
</style>