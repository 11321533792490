<template>
  <v-data-table
    :headers="headers"
    :items="partners"
    class="elevation-1"
    dense
  >
    <template v-slot:item.status="{ item }">
      <div class="text-center" :key="item.id">
        <v-switch
          v-model="item.status"
          :error="!item.status"
          :success="item.status"
          class="mt-1 ml-6 justify-end "
          dense
          hide-details
        ></v-switch>
      </div>
    </template>
    <template v-slot:item.actions="{ item }" >
      <div class="text-right" :key="item.id">
        <v-btn
          
          color="blue"
          x-small
          class="ma-2"
          dark
         height="30px"
        >
          <v-icon small>mdi-wrench-outline</v-icon>
        </v-btn>
        <v-btn
          color="blue"
          x-small
          height="30px"
          class="ma-1"
          dark
          @click="$emit('show', item)"
        >
          <v-icon small>mdi-eye-outline</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          x-small
          height="30px"
          class="ma-1"
          dark
          @click="$emit('edit', item)"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          color="error"
          x-small
          height="30px"
          class="ma-1"
          dark
          @click="$emit('delete', item.id)"
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { API_URL } from '@/api'
export default {
  name: 'PartnerTable',
  props: {
    partners: Array
  },
  data: () => ({
    apiUrl: API_URL,
  }),
  computed: {
    headers () {
      return [
        { text: "Nome", align: "start", sortable: false, value: "name" },
        { text: "Email", value: "email" },
        { text: "Telefone", value: "telephone" },
        { text: "Status", align: "center", value: "status" },
        { text: "Acções", align: "right", value: "actions", sortable: false }
      ];
    }
  }
}
</script>