<template>
  <v-container class="fill-heigt" fluid>
    <v-row>
      <v-col cols="12" md="8">
        <div class="text-left subtitle" style="margin-top:3%">
          Painel de parceiros
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
      </v-col>
      <v-col cols="12" md="3" class="text-end">
        <v-btn
          color="primary"
          @click.prevent="dialog = !dialog"
        >
          <v-icon
            left
          >mdi-plus</v-icon>Novo Parceiro
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <partner-table :partners="getPartners" @delete="openConfirmeDialog" @edit="edit" @show="showPartner" />
      </v-col>
    </v-row>
    <add-partner-dialog :dialog="dialog" :show="show" :partner="partner" @close="closeAddDialog()" />
    <confirm-dialog 
      :dialog="confirm" 
      :id="removeId" 
      question="Pretende remover o Client?" 
      @yes="deletePartner" 
      @no="confirm = false" 
    />
    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </v-container>  
</template>

<script>
import { DELETE_PARTNER_MUTATION } from './../graphql/Mutation'
import { PARTNERS_QUERY } from './../graphql/Query'
import { mapActions, mapGetters } from 'vuex'
import { formatError } from '@/utils'
import AddPartnerDialog from '../components/AddPartnerDialog.vue'
import PartnerTable from '../components/PartnerTable.vue'
import Partner from '@/models/Partner'
import ConfirmDialog from './../../../components/ConfirmDialog.vue'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
import dialogMixins from '@/mixins/dialog'
export default {
  name: 'Partner',
  components: { PartnerTable, AddPartnerDialog, ConfirmDialog, ErrorDialog, ProgressDialog, SuccessDialog },
  mixins: [
    dialogMixins
  ],
  data: () => ({
    dialog: false,
    partner: new Partner(),
    partners: []
  }),
  apollo: {
    partners: {
      query: PARTNERS_QUERY
    }
  },
  watch: {
    partners: function(val) {
      this.setPartnersState(val)
    }
  },
  computed: {
    ...mapGetters({
      getPartners: 'partner/getPartners'
    })
  },
  methods: {
    ...mapActions({
      removePartnerState: 'partner/removePartner',
      setPartnersState: 'partner/setPartners'
    }),
    closeAddDialog () {
      this.partner = new Partner()
      this.dialog = false;
      this.show = false;
    },
    async deletePartner (id) {
      this.confirm = false
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: DELETE_PARTNER_MUTATION,
          variables: {
            id
          }
        })
        this.removePartnerState(id)
        this.success = 'Parceiro removido com sucesso!'
        this.showSuccess = true
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    edit (partner) {
      this.partner = { ...partner, role: partner.user.role, office: partner.user.office }
      delete this.partner.user
      this.dialog = true
    },
    openConfirmeDialog (id) {
      this.removeId = id
      this.confirm = true
    },
    showPartner (partner) {
      this.partner = { ...partner, role: partner.user.role }
      this.show = true
      this.dialog = true
    }
  }
}
</script>